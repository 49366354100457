<template>
  <div>
    <div :class="isPrinting ? 'd-block' : 'd-none'" id="printContents" class="border">
      <div style="background-color: #fff">
        <div>
          <div style="background-color: #ff151f" class="py-3 pr-2 pl-2">
            <b-img :src="header" style="max-width: 250px" class="d-block mx-auto"/>
          </div>
          <div cols="12">
            <h1 class="mt-3 text-uppercase text-center font-weight-bolder" style="color: #ff151f">
              La Cámara de Comercio de Medellín
            </h1>
            <h3 class="font-weight-normal text-center">
              Certifica que
            </h3><br />
            <h1 class="text-center border-bottom d-block mx-auto pb-50" style="max-width: 480px">
              {{ loggedMember.name }} {{ loggedMember.surname }}
            </h1>
            <h3 class="font-weight-normal mt-3 mb-1 text-center">
              Asistió a
            </h3>
          </div>
          <h2 :class="isSmall ? '' : 'mb-2'" class="text-center font-weight-bolder">{{ collectiveName }}</h2>
          <h4 class="text-center mb-5 pb-3">Realizado en Medellín, a {{timestampToTime(currentCollective.startDate)}}</h4>
          <h4 class="text-center text-uppercase border-top d-block mx-auto mt-1 pt-5 mb-0 pt-50" style="max-width: 300px">Lina Vélez Nicholls</h4>
          <p class="text-center pb-75">Presidenta Ejecutiva</p>
        </div>
      </div>
    </div>
    <div class="border mx-lg-2">
      <div>
        <div>
          <div style="background-color: #ff151f" class="py-1 py-lg-2">
            <b-img :src="header" style="max-width: 150px" class="d-block mx-auto"/>
          </div>
          <div cols="12">
            <component :is="isSmall ? 'p' : 'h4'" class="mt-50 mt-lg-2 text-uppercase text-center font-weight-bolder" style="color: #ff151f">
              La Cámara de Comercio de Medellín
            </component>
            <component :is="isSmall ? 'h6' : 'h5'" class="font-weight-normal text-center mb-0">
              Certifica que
            </component><br />
            <component :is="isSmall ? 'h5' : 'h4'" class="text-center border-bottom d-block mx-auto pb-50 modal-title">
              {{ loggedMember.name }} {{ loggedMember.surname }}
            </component>
            <component :is="isSmall ? 'h6' : 'h5'" class="font-weight-normal mt-1 mb-1 text-center">
              Asistió a
            </component>
          </div>
          <component :is="isSmall ? 'h6' : 'h5'" class="text-center font-weight-bolder">{{ currentCollective.name }}</component>
          <component :is="isSmall ? 'h6' : 'h5'" class="text-center">Realizado en Medellín, a {{timestampToTime(currentCollective.startDate)}}</component>
          <component :is="isSmall ? 'h6' : 'h5'" class="text-center text-uppercase border-top d-block mx-auto mt-5 pt-5 mb-0 pt-50 modal-title">Lina Vélez Nicholls</component>
          <p class="text-center">Presidenta Ejecutiva</p>
        </div>
      </div>
    </div>
    <!-- Actions -->
    <div id="actions" :class="isPrinting ? 'd-none' : 'd-block'">
      <div class="d-flex flex-wrap justify-content-center mb-2">
        <b-button class="font-weight-bold mt-1 mr-50" :variant="'primary'" @click="handlePrintCode()">
          <span class="d-flex pb-25"><feather-icon icon="PrinterIcon" size="18" class="d-block mx-auto mr-1" /><span
            class="pl-50 pt-25">{{
            $t('settings.print-certificate')
          }}</span></span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import moment from 'moment';
import MedellinLogo from '@/assets/images/placeholders/light/Medellin.png'
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default {
  name: 'PassportBO',
  components: {
    SafeImg,
    CollectiveLogo,
  },
  props: {
    loggedMember: {
      type: Object,
      required: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    copied: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isStaff: false,
      isModalOpened: false,
      isCopied: false,
      isModalAttendanceOpened: false,
      isSmall: false,
      isPrinting: false,
    };
  },
  computed: {
    collectiveName() {
      if (typeof this.currentCollective.name === 'string') {
        return this.currentCollective.name;
      }
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.currentCollective.name);
    },
    header() {
      return MedellinLogo;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    placeholder() {
      return HeaderPlaceholder;
    },
    organizations() {
      return this.loggedMember.organizations;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    headerBg() {
      return {
        'border-radius': '1.2rem 1.2rem 0 0',
        'max-height': '200px',
        'max-width': this.isSmall ? '319px' : '499px',
        'object-fit': 'cover',
      };
    },
    mainCommunity() {
      return this.$store.getters.mainCollective;
    },
    imgUrl() {
      return ErrorPlaceholder;
    },
    qrUrl() {
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.currentCollective.subdomain || 'app',
      ).replace('{slug}', this.currentCollective.slug);
      return `${url}/backoffice/members/${this.loggedMember.username}`;
    },
    link() {
      return `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${encodeURIComponent(
        this.qrUrl,
      )}&choe=UTF-8`;
    },
    logoImage() {
      if (this.collective.logoURL != null) {
        return getImageResource(this.collective.logoURL);
      }
      if (this.collective.header != null && this.collective.header.logo_url != null) {
        return getImageResource(this.collective.header?.logo_url);
      }
      return '';
    },
  },
  watch: {
    printing(value) {
      if (value === true) {
        this.handlePrintCode();
      }
    },
    copied(value) {
      if (value === true) {
        this.handleClipboardCopied();
      }
    },
  },
  created() {
    this.isStaff = this.currentCollective.isStaff;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.qrUrl);
    },
    handleResize() {
      this.isSmall = window.innerWidth < 1024;
    },
    handlePrintCode() {
      this.isPrinting = true;
      document.body.innerHTML = document.getElementById("printContents").innerHTML;
      window.print();
      window.onafterprint = this.afterPrint();
    },
    afterPrint() {
      this.isPrinting = false;
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

@media print {
  @page {
    size: landscape;
  }

  #printContents {
    background-color: #ffffff;
  }

}
.modal-title {
  max-width: 380px;
  @media (max-width: 992px) {
    max-width: 200px;
  }
}
</style>
