<template>
  <div>
    <b-row id="printContents" class="mx-lg-50">
      <div :style="isSmall ? 'width: 500px; height: 330px' : 'width: 700px; height: 495px'" class="d-block mx-auto box">
        <div :class="isSmall ? 'p-2 box-inner box-inner--responsive' : 'box-inner'">
          <div class=" mt-1 d-flex flex-column justify-content-between" body-class="p-0">
            <b-row>
              <b-col cols="8">
                <component :is="isSmall ? 'h5' : 'h1'" class="text-primary text-uppercase title">
                  {{ $t('backoffice.members.certificate.name') }}
                </component>
                <component :is="isSmall ? 'small' : 'p'" class="font-weight-normal text-uppercase title">{{
                    $t('backoffice.members.certificate.type')
                }}</component><br /><br />
                <component :is="isSmall ? 'small' : 'p'" class=" text-primary"
                  :class="isSmall ? 'mt-2 pt-50' : 'mt-n2'">{{ $t('backoffice.members.certificate.presented') }}
                </component>
                <component :is="isSmall ? 'h5' : 'h1'">
                  {{ loggedMember.name }} {{ loggedMember.surname }}
                </component>
                <component :is="isSmall ? 'small' : 'p'" class="font-weight-normal mt-1 text-primary">
                  {{ $t('backoffice.members.certificate.participation') }}
                </component>
              </b-col>
              <b-col>
                <div :class="isSmall ? 'gbadge gbadge--responsive' : 'gbadge'">
                  <div :class="isSmall ? 'clearfix gbadge-inner gbadge-inner--responsive' : 'clearfix gbadge-inner'">
                    <collective-logo :logo="logoImage" :class="isSmall ? 'logoImage-responsive' : 'logoImage'" />
                  </div>
                </div>
              </b-col>
            </b-row>
            <component :is="isSmall ? 'h5' : 'h3'" :class="isSmall ? '' : 'mb-2'">{{ collectiveName }}</component>
            <component :is="isSmall ? 'small' : 'p'" v-if="currentCollective.startDate"
              class="font-weight-normal text-primary"> {{ $t('backoffice.members.certificate.dates.title') }} <span
                class="font-weight-bold"> <span v-if="currentCollective.endDate" class="font-weight-normal"> {{
                    $t('backoffice.members.certificate.dates.from')
                }}</span><span v-else>{{
    $t('backoffice.members.certificate.dates.on')
}}</span>{{ timestampToTime(currentCollective.startDate)}}</span>
              <!-- del -->
              <span v-if="currentCollective.endDate" class="font-weight-normal"><span
                  v-if="currentCollective.startDate"> {{ $t('backoffice.members.certificate.dates.to') }}</span> <span
                  v-if="!currentCollective.startDate">{{ $t('backoffice.members.certificate.dates.on') }}</span>
                <span class="font-weight-bold">{{ timestampToTime(currentCollective.endDate)}}</span></span>
            </component>
            <div class="d-flex justify-content-around " :class="isSmall ? 'mt-2' : 'mt-3'">
              <div>
                <p class="font-weight-bold border-bottom mb-0 mt-2">
                  </p>
                <small class="ml-3 mr-3" :style="isSmall ? 'font-size: 10px' : ''">{{ $t('backoffice.members.certificate.footer.date') }}</small>
              </div>
              <div>
                <p class="font-weight-bold border-bottom mb-0 mt-2">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <small class="ml-3 mr-3" :style="isSmall ? 'font-size: 10px' : ''">{{ $t('backoffice.members.certificate.footer.responsible') }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <!-- Actions -->
    <div id="actions" :class="isPrinting ? 'd-none' : 'd-block'">
      <div class="d-flex flex-wrap justify-content-center mb-2">
        <b-button class="font-weight-bold mt-1 mr-50" :variant="'primary'" @click="handlePrintCode()">
          <span class="d-flex pb-25"><feather-icon icon="PrinterIcon" size="18" class="d-block mx-auto mr-1" /><span
              class="pl-50 pt-25">{{
    $t('settings.print-certificate')
              }}</span></span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import moment from 'moment';
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default {
  name: 'PassportBO',
  components: {
    SafeImg,
    CollectiveLogo,
  },
  props: {
    loggedMember: {
      type: Object,
      required: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    copied: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isStaff: false,
      isModalOpened: false,
      isCopied: false,
      isModalAttendanceOpened: false,
      isSmall: false,
      isPrinting: false,
    };
  },
  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    placeholder() {
      return HeaderPlaceholder;
    },
    organizations() {
      return this.loggedMember.organizations;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    headerBg() {
      return {
        'border-radius': '1.2rem 1.2rem 0 0',
        'max-height': '200px',
        'max-width': this.isSmall ? '319px' : '499px',
        'object-fit': 'cover',
      };
    },
    mainCommunity() {
      return this.$store.getters.mainCollective;
    },
    imgUrl() {
      return ErrorPlaceholder;
    },
    qrUrl() {
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.currentCollective.subdomain || 'app',
      ).replace('{slug}', this.currentCollective.slug);
      return `${url}/backoffice/members/${this.loggedMember.username}`;
    },
    link() {
      return `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${encodeURIComponent(
        this.qrUrl,
      )}&choe=UTF-8`;
    },
    logoImage() {
      if (this.collective.logoURL != null) {
        return getImageResource(this.collective.logoURL);
      }
      if (this.collective.header != null && this.collective.header.logo_url != null) {
        return getImageResource(this.collective.header?.logo_url);
      }
      return '';
    },
    collectiveName() {
      if (typeof this.currentCollective.name === 'string') {
        return this.currentCollective.name;
      }
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.currentCollective.name);
    },
  },
  watch: {
    printing(value) {
      if (value === true) {
        this.handlePrintCode();
      }
    },
    copied(value) {
      if (value === true) {
        this.handleClipboardCopied();
      }
    },
  },
  created() {
    this.isStaff = this.currentCollective.isStaff;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.qrUrl);
    },
    handleResize() {
      this.isSmall = window.innerWidth < 1024;
    },
    handlePrintCode() {
      this.isPrinting = true;
      document.body.innerHTML = document.getElementById("printContents").innerHTML;
      window.print();
      window.onafterprint = this.afterPrint();
    },
    afterPrint() {
      this.isPrinting = false;
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.gbadge {
  display: block;
  margin-top: -54px !important;
  width: 132px;
  height: 155px;
  background-color: #ffffff;
  border: 1px solid #ebe9f1;
  border-top: 0;
  border-bottom: 0;
  position: relative;
  padding: 0 6px 6px;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0 auto;

  &--responsive {
    width: 80px;
    height: 93px;
    margin-top: -35px !important;

    &:after,
    &:before {
      border-left: solid 39.6px transparent !important;
      border-right: solid 39.6px transparent !important;
    }
  }
}

.gbadge:after,
.gbadge:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -1px;
  bottom: -29px;
  border-top: solid 30px #ffffff;
  border-left: solid 66px transparent;
  border-right: solid 66px transparent;
}

.gbadge:before {
  bottom: -30px;
  border-top-color: #d6d6d6;
}

.gbadge .ribbon {
  position: absolute;
  left: -12px;
  top: 73px;
  z-index: 3;
  display: block;
  width: 155px;
  font-size: 9px;
  text-align: center;
  letter-spacing: 0.8px;
  line-height: 1;
  font-weight: 600;
  padding: 8px 12px;
}

.gbadge .ribbon:before {
  content: "";
  width: 100%;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border: 12px solid #d6d6d6;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.gbadge .ribbon:after {
  content: "";
  width: 97.5%;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 1px;
  z-index: -1;
  border: 11px solid #ffffff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.gbadge .gbadge-inner {
  display: block;
  width: 100%;
  height: 149px;
  position: relative;
  z-index: 2;
  background-color: #f3f3f3;
  padding-top: 12px;
  text-align: center;

  &--responsive {
    height: 89.4px;
    padding-top: 5px;

    &:after {
      border-top: solid 16.8px #f3f3f3 !important;
      border-left: solid 35.4px transparent !important;
      border-right: solid 35.4px transparent !important;
    }
  }
}

.gbadge .gbadge-inner:after {
  position: absolute;
  top: 100%;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: solid 28px #f3f3f3;
  border-left: solid 59px transparent;
  border-right: solid 59px transparent;
}

.gbadge .gbadge-inner .g-logo {
  display: inline-block;
  background: url("https://pyt-images.imgix.net/images/web_app/home/google_badge.png") no-repeat;
  background-size: 114px 58px;
  width: 114px;
  height: 58px;
}

.gbadge p {
  color: #d6d6d6;
  line-height: 1.09;
  font-weight: 700;
  margin-bottom: 0;

  padding: 15px 13.5px 0;
  position: relative;
  z-index: 4;
}

.gbadge p span {
  display: block;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 1.09;
}

.gbadge p span:last-child {
  font-size: 21px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.29px;
}

.box {
  position: relative;
  width: 700px;
  padding: 5px;
  background-color: #ffffff;
  border: 3px solid #d6d6d6;

  &:before,
  &:after {
    content: "•";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 14px;
    color: #d6d6d6;
    border: 2px solid #d6d6d6;
    line-height: 12px;
    top: 5px;
    text-align: center;
  }

  &:before {
    left: 5px;
  }

  &:after {
    right: 5px;
  }

  .box-inner {
    position: relative;
    height: 479.2px;
    border: 2px solid #d6d6d6;
    padding: 40px;

    &--responsive {
      height: 314px;
    }

    &:before,
    &:after {
      content: "•";
      position: absolute;
      width: 14px;
      height: 14px;
      font-size: 14px;
      color: #d6d6d6;
      border: 2px solid #d6d6d6;
      line-height: 12px;
      bottom: -2px;
      text-align: center;
    }

    &:before {
      left: -2px;
    }

    &:after {
      right: -2px;
    }

  }
}

.logoImage {
  width: 80px !important;
  height: 80px !important;
  margin-top: 30px;
}

.logoImage-responsive {
  width: 50px !important;
  height: 50px !important;
  margin-top: 20px;
}

.title {
  letter-spacing: 5px;
  @media (max-width: 992px) {
    letter-spacing: 2px;
    margin-bottom: 0px;
  }
}

@media print {
  @page {
    size: landscape;
  }

  #printContents {
    background-color: #ffffff;
  }

}
</style>
