<template>
  <b-modal
    id="export-modal"
    :visible="visible"
    :title="$t('backoffice.members.export')"
    centered
    ok-only
    no-close-on-backdrop
    @hidden="closeModal()"
    @show="defaultSelection"
    :ok-title="$t('backoffice.members.export')"
    @ok="handleExport"
    @change="$emit('change', false)"
  >
    <div v-if="options.length > 0" class="mb-3 mt-1">
      <h5 class="mb-1">
        {{ $t('backoffice.analytics.export-label') }}:
      </h5>
      <v-select
        v-model="mainTypeIDs"
        :placeholder="$t('form.select-placeholder')"
        :get-option-label="(a) => a.label"
        :reduce="(a) => a.id"
        :options="options"
        multiple
      />
    </div>
    <h5 v-else>
      {{ $t('backoffice.analytics.export-label-no-options') }}
    </h5>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'ExportModal',
  components: {
    vSelect,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      options: [],
      mainTypeIDs: '',
    };
  },
  computed: {
    enabledApps() {
      return this.$store.getters.installedApps.data?.map(
        (item) => item.addonSingular
      );
    },
    addons() {
      return [
        { id: 9, name: 'organization', label: this.$t('organizations.title') },
        { id: 7, name: 'subcommunity', label: this.$t('subcommunities.title') },
        { id: 1, name: 'event', label: this.$t('events.title') },
        { id: 10, name: 'challenge', label: this.$t('challenges.title') },
        { id: 3, name: 'project', label: this.$t('projects.title') },
        { id: 11, name: 'service', label: this.$t('services.title') },
        { id: 2, name: 'course', label: this.$t('courses.title') },
      ];
    },
  },
  async created() {
    await this.getInstalledApps();
    this.enabledApp();
  },
  methods: {
    defaultSelection() {
      this.mainTypeIDs = this.options[0]
    },
    async getInstalledApps() {
      await this.$store.dispatch('getInstalledApps');
    },
    enabledApp() {
      const names = this.addons.map(addon=> addon.name);
      names.map(name => {
        if (this.enabledApps.filter(item=> item === name).length > 0) {
          this.addons.filter(it=> {
            if (name === it.name) {
              this.options.push(it);
            }
          });
        }
      });
      this.options.unshift({ id: 0, name: 'basic', label: this.$t('backoffice.analytics.basic-label') })

    },
    closeModal() {
      this.mainTypeIDs = '';
      this.$bvModal.hide('export-modal');
    },
    handleExport() {
      const exporID = Array.isArray(this.mainTypeIDs) ? this.mainTypeIDs.filter(item => item != 0) : '';
      this.$emit('export', exporID)
    },
  },
};
</script>
